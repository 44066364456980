<template>
    <a-spin :spinning="loading">
        <a-card class="box-card" header="菜单管理" style="margin: 10px;">
            <a-button type="primary" size="small" icon="plus" style="margin-bottom: 20px;" @click="addMenu(0, '无')">添加一级菜单</a-button>
            <a-tree
                :tree-data="data"
                :replaceFields="replaceFields"
                defaultExpandAll
                style="width:500px;"
            >
                <template slot="custom" slot-scope="title">
                    <span class="node-title">
                        <a-icon :type="title.icon" />
                    &nbsp;   {{ title.name }}
                    </span>
                    <span class="custom-tree-node">
                        <span class="icon-wrap">
                            <a-button v-if="title.type !== 3" type="link" size="small" @click="() => addMenu(title.id, title.name)">添加下级菜单</a-button>
                            <a-button v-else type="link" style="color:gray" size="small">添加下级菜单</a-button>
                            <a-button type="link" size="small" @click="() => modifyMenu(title)">修改</a-button>
                            <a-popconfirm
                                title="确认删除?"
                                ok-text="确认"
                                cancel-text="取消"
                                placement="topLeft"
                                @confirm="remove(title)">
                                <a-button type="link" size="small">删除</a-button>
                            </a-popconfirm>
                        </span>
                    </span>
                </template>
            </a-tree>
            <menu-form ref="menuDialog" @success="findNavMenuTree()"></menu-form>
        </a-card>
    </a-spin>
</template>
<script>
import MenuForm from "./modules/MenuForm"
import { mapState } from 'vuex'
export default {
    components: {
        MenuForm
    },
    data () {
        return {
            loading: false,
            data: [],
            replaceFields: { children:'children',title:'name', key:'id' }
        }
    },
    computed: {
        ...mapState({
        })
    },
    mounted () {
        this.findNavMenuTree()
    },
    methods: {
        findNavMenuTree () {
            this.loading = true
            this.$api.menu.findNavMenuTree().then(res => {
                this.loading = false
                if (res.status === 0) {
                    this.data = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                console.log('findManagerMenus  Error:', err)
                this.$message.error('网络繁忙')
            }).finally(_ => {
                this.loading = false
            })
        },
        // 添加菜单
        addMenu (parentId, parentName) {
            this.$refs.menuDialog.show(parentId, parentName, null)
        },
        modifyMenu (menu) {
            this.$refs.menuDialog.show(menu.parentId, menu.parentName, menu)
        },
        remove (menu) {
            this.loading = true
            this.$api.menu.del(menu.id).then(res => {
                if (res.status === 0) {
                    this.$message.success('删除成功')
                    this.findNavMenuTree()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                console.log('removeMenu  error: ', err)
                this.$message.error('网络忙')
            }).finally(_ => {
                this.loading = false
            })
        }
    }
}
</script>
<style scoped>
    .node-title {
        width: 200px;
        display: inline-block;
    }
    .box-card /deep/ .ant-tree li .ant-tree-node-content-wrapper{
        width: 500px;
    }
    .box-card /deep/ .ant-card-body {
        width: 658px;
        position:relative;
    }

    .custom-tree-node {
        flex: 1;
        display: inline-block;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        width: 300px;
        text-align: left;
        position: absolute;
        right:0px;
    }
    .custom-tree-node .icon-wrap {
        width: 200px;
        text-align: right;
    }
</style>
